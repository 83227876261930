import React, { Component } from "react";
import "./frontpage.css";
import AOS from 'aos';
import { Link } from 'react-scroll';
import './Frontpage.css'; 

AOS.init({})

class Frontpage extends Component {
  constructor() {
    super();
    this.state = {
      indexActive: 1,
      sticky: false,
    };

  }


  toggle(i) {
    if (this.state.indexActive === i) {
      this.setState({ indexActive: 0 });
    } else {
      this.setState({ indexActive: i });
    }
  }


  render() {

    return (
      <div className="wrapper">

        <section className="first-screen section section--no-pad-top" style={{backgroundImage: 'url(img/main_bg.png'}} id="main">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 data-aos="fade-up" data-aos-anchor="#main" data-aos-delay="200">
                <span style={{color:'#FF0000'}}><h1></h1></span>
  
                  
                  The <span style={{color:'#ef5350', fontSize: '70px', fontWeight: 'bold'}}>MAGACAPY</span> <span style={{ fontSize: '70px', fontWeight: 'bold'}}></span>
{' '}<span>is here to fight the globalist!!</span></h1>
                <p data-aos="fade-up" data-aos-anchor="#main" data-aos-delay="300">
               Support the MAGACAPY operation today!! 
                  <br /><span data-aos="fade-up" data-aos-anchor="#main" data-aos-delay="300">
                   
               </span>
                </p>

              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="first-screen__btns-wrap" data-aos="fade-up" data-aos-anchor="#main" data-aos-delay="500">

                  <a href="https://uniswap.org/" target="_blank" className="btn btn--medium btn--orange">Buy MAGACAPY</a>

                  <a href="https://t.me"  target="_blank" className="btn btn--medium btn--white">Telegram</a>

                  <a href="https://www.dextools.io/app/en/pairs" target="_blank" className="btn btn--medium btn--blue">Live Chart</a>



                </div>
              </div>
            </div>

            <div  className='row' >


              <div className="Atom" style={{marginRight: '42%'}}>
                <div className="Atom-nucleus">
                  <img style={{ borderRadius: '70px'}} alt="shib logo" src="img/capylogo.png" />
                </div>

                <div className="Atom-orbit Atom-orbit--left Atom-orbit--foreground" >
                  <div className="Atom-electron" style={{background: 'none'}} >
                    <img  alt="Polygon" style={{background: 'none'}} src="img/magahat.png" />
                  </div>
                </div>

                <div className="Atom-orbit Atom-orbit--right Atom-orbit--foreground">
                  <div className="Atom-electron" style={{background: 'none'}}>



                    <img  alt="BABE" height="28" width="28" style={{background: 'none'}} src="img/magahat.png" />
                  </div>
                </div>

                <div className="Atom-orbit Atom-orbit--top Atom-orbit--foreground">
                  <div className="Atom-electron" style={{background: 'none'}}>
                    <img  alt="kadena  (KDA)" height="28" width="28" style={{background: 'none'}} src="img/magahat.png" />

                  </div>
                </div>
                <div className="Atom-orbit Atom-orbit--bottom Atom-orbit--foreground">
                  <div className="Atom-electron" style={{background: 'none'}}>
                    <img  alt="flux  (FLUX)" height="28" width="28" style={{background: 'none'}} src="img/magahat.png" />

                  </div>
                </div>




              </div>


            </div>

          </div>
        </section>

        <section className="about section section--no-pad-bot" id="about" style={{backgroundImage: 'url(img/bg_1.jpg', backgroundPosition: 'left top'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img data-aos="fade-right" src="img/about-img-1.png" className="img-responsive" alt="" />
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <div className="section-header section-header--tire section-header--small-margin">
                  <h4>About MAGACAPY</h4>
                  <h2>
                    MAGACAPY <span>A meme token uniting all the patriotic meme FRENS across the Ecosystem.</span>
                  </h2>
                </div>

                <p className="text-margin-bot">
                We are literlly a based Capybara what more do you want?
                </p>

            
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="partners-logo__block" data-aos="fade-up">
                  <div className="partners-logo__item">
                    <a href="https://uniswap.org/" target="_blank" rel="noreferrer">
                      <img src="img/uniswapv2.png" alt="CoinMarketCap logo" />
                    </a>
                  </div>
                  <div className="partners-logo__item">
                    <a href="https://coinmarketcap.com" target="_blank" rel="noreferrer">
                      <img src="img/cmc-logo.png" alt="CoinMarketCap logo" />
                    </a>
                  </div>
            

          

                  <div className="partners-logo__item">
                    <a href="https://www.coingecko.com" target="_blank" rel="noreferrer">
                      <img src="img/coingecko-logo.png" alt="CoinGecko logo" />

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section infoblock" style={{backgroundImage: 'url(img/infoblock_bg.png)'}} id="stat">

          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--white section-header--medium-margin">
                  <h4>MAGACAPY Stuff</h4>
                  <h2>Token features</h2>
                </div>

                <div className="infoblock__list">
                  <div className="infoblock__item" data-aos="fade-up" data-aos-delay="100">
                    <p>
                      Buys
                    </p>
                    <span style={{ color: '#f3d667' }}>1% <p style={{ color: '#f3d667' }}>Low 1% Buy Tax to support the opereration</p></span>
                  </div>
                  {/*<div className="infoblock__item" data-aos="fade-up" data-aos-delay="200">
                    <p>
                      Liquidity 
                    </p>
                    <span style={{ color: '#f9778a' }}>1% <p style={{ color: '#f3d667' }}>1%  on all transactions done on protocol goes to adding liquidity to the pool.</p></span>
    </div> */}
                  <div className="infoblock__item" data-aos="fade-up" data-aos-delay="300">
                    <p>
                      Sells
                    </p>
                    <span style={{ color: '#77c0f6' }}>1% <p style={{ color: '#f3d667' }}>Low 1% Sell Tax to support the opereration.</p></span>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section services" id="services" style={{backgroundImage: 'url(img/bg_4.png)'}}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--medium-margin">
                  <h4>MAGACAPY </h4>
                  <h2>Why buy MAGACAPY token?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="200">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-1.svg" alt="" />
                  </div>
                  <div className="service-item__title">ETH-powered</div>
                  <p>
                    ETH is a proven blockchain which allows degens to degen. 
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="300">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-2.svg" alt="" />
                  </div>
                  <div className="service-item__title">Capybara MAGA Theme</div>
                  <p>
                    This is the first MAGA Themed Capybara. 
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="400">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-3.svg" alt="" />
                  </div>
                  <div className="service-item__title">Trump Season</div>
                  <p>
                   The MAGA movement is going to be trending into the future.  
                  </p>
                </div>
              </div>

      {/*
              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="500">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-4.svg" alt="" />
                  </div>
                  <div className="service-item__title">Yield Farms</div>
                  <p>
                    The kSHIB protocol will offer a rich selection of yield farms for token holders with V2 release. 
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="600">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-5.svg" alt="" />
                  </div>
                  <div className="service-item__title">Staking</div>
                  <p>
                    kSHIB holders will have multiple unique staking options.  
                  </p>
                </div>
              </div> 

              <div className="col-sm-6 col-lg-4" data-aos="fade-left" data-aos-delay="700">
                <div className="service-item">
                  <div className="service-item__icon">
                    <img src="img/services-icon-6.svg" alt="" />
                  </div>
                  <div className="service-item__title">Deflationary</div>
                  <p>
                    Over time the token supply decreases causing (supply shock) (5% of every kSHIB transaction is burned)
                  </p>
                </div>

              </div>*/}

            </div>  

          </div>


        </section>

        <section className="section map" id="map" style={{ backgroundImage: 'url(img/bg_2.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--medium-margin">
                  <h4>Go for MAGACAPY</h4>
                  <h2>Road Map</h2>
                </div>
                <div className="roadmap" data-aos="fade-up">
                  <div className="roadmap__item roadmap__item--past">
                    <div className="roadmap__item-title">
                      Phase 1
                    </div>

                    <div className="roadmap__item-marker">
                    </div>

                    <div className="roadmap__item-text">
                      <ul className="data__list">
                        <li>MAGA CAPYS Join Forces</li>
                      
                  
                      </ul>
                    </div>
                  </div>

                  <div className="roadmap__item roadmap__item">
                    <div className="roadmap__item-title">
                      Phase 2
                    </div>

                    <div className="roadmap__item-marker">
                    </div>

                    <div className="roadmap__item-text">
                      <ul className="data__list">
                      <li>MAGA CAPYS Shill</li>
                   
                      </ul>
                    </div>
                  </div>

                  <div className="roadmap__item">
                    <div className="roadmap__item-title">
                      Phase 3
                    </div>

                    <div className="roadmap__item-marker">
                    </div>

                    <div className="roadmap__item-text">
                      <ul className="data__list">
                      <li>MAGA CAPYS Shill More</li>
                  
                      </ul>
                    </div>
                  </div>

                  <div className="roadmap__item">
                    <div className="roadmap__item-title">
                      Phase 4
                    </div>

                    <div className="roadmap__item-marker">
                    </div>

                    <div className="roadmap__item-text">
                      <ul className="data__list">
                      <li>MAGA CAPYS shill even more</li>
                        
                      </ul>
                    </div>
                  </div>

                  <div className="roadmap__item">
                    <div className="roadmap__item-title">
                      Phase 5
                    </div>

                    <div className="roadmap__item-marker">
                    </div>

                    <div className="roadmap__item-text">
                      WAGMI
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section process" style={{backgroundImage: 'url(img/bg_4.png)' }} id="how_to_buy">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--medium-margin">
                  <h4>hodl the MAGACAPY</h4>
                  <h2>How to get MAGACAPY</h2>
                </div>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-sm-6 col-xl-3">
                <div className="process-item" style={{ border: '3px solid #16bf86' }} >
                  <div className="process-item__icon"><img src="img/pr-icon-1.svg" alt="" /></div>
                  <div className="process-item__title">Step 1:<p>Buy ETH</p></div>
                  <p>
                    The easiest way to buy ETH is through the trusted centralized exchanges (CEX) like{' '}
                    <a href="https://www.coinbase.com/" target="_blank" rel="noreferrer">Coinbase</a> | <a href="https://binance.com/" target="_blank" rel="noreferrer">Binance.</a> <a href="https://www.kucoin.com/" target="_blank" rel="noreferrer">Kucoin</a> | 
                  </p>
                  <div className="process-item__arrow" style={{ backgroundColor: '#16bf86' }}><img src="img/arrow.png" alt="" /></div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="process-item" style={{ border: '3px solid #5775cf' }} >
                  <div className="process-item__icon"><img src="img/pr-icon-2.svg" alt="" /></div>
                  <div className="process-item__title">Step 2:<p>Install a wallet</p></div>
                  <p>
                    We recommend using a trusted wallet such as <a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask</a> {' '}
                   .
                    DO NOT share your password or private keys ever! 
                  </p>
                  <div className="process-item__arrow" style={{ backgroundColor: '#5775cf' }}><img src="img/arrow.png" alt="" /></div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="process-item" style={{ border: '3px solid #f2718b' }}>
                  <div className="process-item__icon"><img src="img/pr-icon-3.svg" alt="" /></div>
                  <div className="process-item__title">Step 3:<p>Send ETH to wallet</p></div>
                  <p>
                    Transfer ETH from Step 1 to your Metamask wallet. Ensure you double check address you are sending to.
                  </p>
                  <div className="process-item__arrow" style={{ backgroundColor: '#f2718b' }}><img src="img/arrow.png" alt="" /></div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3" >
                <div className="process-item" style={{ border: '3px solid #ff903e' }}>
                  <div className="process-item__icon"><img src="img/pr-icon-4.svg" alt="" /></div>
                  <div className="process-item__title">Step 4:<p>Buy on Uniswap</p></div>
                  <p>
                    You may now swap your ETH for some MAGACAPY on <a href="https://app.uniswap.org/swap" target="_blank" rel="noreferrer">Uniswap</a> {' '}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section data section--no-pad-top" id="token" style={{ backgroundImage: 'url(img/bg_2.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-header section-header--tire section-header--medium-margin">
                  <h4>the MAGACAPY ratio</h4>
                  <h2>MAGACAPYNOMICS</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">

                <div className="data-progress" data-aos="fade-up">
              
                  <div className="data-progress__item" style={{ boxShadow: '0 0 15px #16bf86', backgroundColor: '#16bf86', width: '100%' }}>
                    <p><span>100%</span> - listed on the open market</p>
                  </div>
              
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">

                <ul className="data__list">
                  <li>
                    <b>Symbol:</b>
                    MAGACAPY
                  </li>
                  <li>
                    <b>Network:</b>
                    Ethereum Blockchain
                  </li>
                  <li>
                    <b>Contract:</b>
                    <a href="" target="_blank" rel="noreferrer">TBD</a>
                  </li>
                  <li>
                    <b>Total supply:</b>
                    10 billion
                  </li>
                </ul>

                <div className="data__info">
                  <div className="data__info-item">
                    Dex
                    <b style={{ color: '#16bf86' }}>Uniswap</b>
                  </div>
                  <div className="data__info-item">
                   Pool
                    <b style={{ color: '#5775cf' }}>WETH / MAGACAPY</b>
                  </div>
                  <div className="data__info-item">
                    Cost of 1 WETH to MAGACAPY TBD 
                    <b style={{ color: '#f2718b' }}>TBD</b>
                  </div>
                </div>

                <ul className="data__list">
              
                  <li>
                    <b>Initial Liquidity lock duration: </b>
                    90 days
                  </li>
                  <li>
                    <b>Min/Max buy limits:</b>
                    none
                  </li>
                </ul>

                <a href="https://uniswap.org/" target="_blank" className="btn btn--blue btn--medium">Buy MAGACAPY </a>
              </div>
              <div className="col-lg-6">
                <div className="data__images">
                  <div className="data__images-logo">
                    <img src="img/capylogo.png" alt="" />
                    <p>MAGACAPY Token</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section contact" style={{ backgroundImage: 'url(img/footer_bg.png)' }} id="faq">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--white section-header--center section-header--medium-margin">
                  <h4>FAQ</h4>
                  <h2>Project Insight</h2>
                </div>

                <ul className="accordion">
                  <li>
                    <h3 className={this.state.indexActive === 1 ? 'active' : ''} onClick={() => this.toggle(1)} >Why MAGACAPY</h3>
                    <p style={{ display: this.state.indexActive === 1 && 'block' }}>
                      CAPYBARA'S + 2024 Election = "Why Not?"
                    </p>
                  </li>
                  <li>
                    <h3 className={this.state.indexActive === 2 ? 'active' : ''} onClick={() => this.toggle(2)}  >What dex will you supply liquidity on?</h3>
                    <p style={{ display: this.state.indexActive === 2 && 'block' }}>
                      100% of the supply will go into a Uniswap V2 Pool. 
                    </p>
                  </li>
                  <li>
                    <h3 className={this.state.indexActive === 3 ? 'active' : ''} onClick={() => this.toggle(3)} >What features will your project offer?</h3>
                    <p style={{ display: this.state.indexActive === 3 && 'block' }}>
                      Based memes with a Based community 
                     
                     
                    </p>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="copyright">© 2024 - MAGACAPY</div>
              </div>
              <div className="col-lg-3 col-md-6">

              </div>
              <div className="col-lg-3 col-md-6">
                <div className="social-block">
                  <div className="social-block__title">
                    Join our community
                  </div>
                  <ul className="social-list">
                    <li className="social-list__item">
                      <a href="https://twitter.com" target="_blank" rel="noreferrer" className="social-list__link">
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                      </a>
                    </li>
                    <li className="social-list__item">
                      <a href="https://t.me" target="_blank" rel="noreferrer" className="social-list__link">
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="telegram" className="svg-inline--fa fa-telegram fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"></path></svg>
                      </a>
                    </li>
         
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

    )
  }
}

export default Frontpage;

