import React, { Fragment } from "react";
import { HashRouter, Route, Switch } from 'react-router-dom';
import Header from "./components/Header/index";
import Frontpage from "./components/Frontpage/index";

function App() {
  return (
    <div >

      <HashRouter>
        <Switch>
          <Fragment>
            <Header />
            <Route exact path="/" component={Frontpage} />
          </Fragment>
        </Switch>
      </HashRouter>

    </div>
  );
}

export default App;